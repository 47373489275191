import Mock from "../mock";

const database = {
  information: {
    name: 'Heder Soares',
    aboutContent: "Sou Designer Gráfico com mais de 15 anos de experiência. Sou apaixonado por design e tudo que envolve criatividade. Meu objetivo é sempre entregar o melhor resultado para o cliente, com qualidade e eficiência.",
    age: 41,
    phone: '+238 970 50 30',
    nationality: 'Cabo-Verdiano',
    language: 'Português, Inglês, Espanhol',
    email: '',
    address: 'Praia, Cabo Verde',
    freelanceStatus: 'Disponível',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: '',
      dribbble: '',
      github: ''
    },
    brandImage: '/images/brand-image.jpg',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image.jpg',
    cvfile: '/files/empty.pdf'
  },
  services: [
    {
      title: "Design Gráfico",
      icon: 'brush-alt',
      details: "criar elementos visuais, como logotipos, layouts, e imagens, para comunicar mensagens, seja em mídia impressa ou digital."
    },
    /* {
      title: "Web Development",
      icon: 'code',
      details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    },
    {
      title: "Mobile Application",
      icon: 'mobile',
      details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    } */
  ],
  reviews: [
    /* {
      id: 1,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: 'Burdette Turner',
        designation: 'Web Developer, Abc Company'
      }
    },
    {
      id: 2,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: 'Susan Yost',
        designation: 'Client'
      }
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: 'Irving Feeney',
        designation: 'Fiverr Client'
      }
    } */
  ],
  skills: [
    {
      title: "HTML5",
      value: 95
    },
    {
      title: "CSS3",
      value: 90
    },
    {
      title: "Javascript",
      value: 70
    },
    {
      title: "jQuery",
      value: 85
    },
    {
      title: "ReactJS",
      value: 80
    },
    {
      title: "Photoshop",
      value: 65
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Agenda Calendário",
      //subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/agenda.jpg",
      largeImageUrl: ["/images/agenda.jpg"],
      url: '#'
    },
    {
      id: 2,
      title: "BLOCO DE NOTAS",
      //subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/BLOCO DE NOTAS.jpg",
      largeImageUrl: [
        "/images/BLOCO DE NOTAS.jpg",
        "/images/BLOCO DE NOTAS.jpg"
      ],
      url: '#'
    },
    {
      id: 3,
      title: "BRANDING",
      //subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/BRANDING.jpg",
      url: '#'
    },
    {
      id: 4,
      title: "DESDOBRAVEL",
      //subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/DESDOBRAVEL.jpg",
      largeImageUrl: ["/images/DESDOBRAVEL.jpg"],
      url: '#'
    },
    {
      id: 5,
      title: "POSTER SHELL GAS",
      //subtitle: "Mug with awesome style",
      imageUrl: "/images/POSTER SHELL GAS.jpg",
      largeImageUrl: ["/images/POSTER SHELL GAS.jpg"]
    },
    {
      id: 6,
      title: "SACO PAPEL",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/SACO PAPEL 1.jpg",
      largeImageUrl: ["/images/SACO PAPEL 1.jpg"]
    },
    {
      id: 7,
      title: "NEWSLETTER",
      //subtitle: "Pendrive with great design & flexible.",
      imageUrl: "/images/NEWSLETTER.png",
      largeImageUrl: ["/images/NEWSLETTER.png"],
      url: 'https://twitter.com'
    },
    {
      id: 8,
      title: "Saco PAPEL",
      //subtitle: "Clip sticker mockup design.",
      imageUrl: "/images/Saco PAPEL.jpg",
      largeImageUrl: ["/images/Saco PAPEL.jpg"]
    },
    {
      id: 9,
      title: "PASTA DOCUMENTOS",
      //subtitle: "Beautiful packet & product design.",
      imageUrl: "/images/PASTA DOCUMENTOS.jpg",
      largeImageUrl: ["/images/PASTA DOCUMENTOS.jpg"]
    },
    {
      id: 10,
      title: "Tapete Rato1",
      //subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/Tapete Rato1.jpg",
      largeImageUrl: ["/images/Tapete Rato1.jpg"],
      url: '#'
    },
    {
      id: 11,
      title: "REVISTAS",
      //subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/REVISTAS.jpg",
      largeImageUrl: [
        "/images/REVISTAS.jpg",
        "/images/REVISTAS.jpg"
      ],
      url: '#'
    },
    {
      id: 12,
      title: "POSTER",
      //subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/POSTER.jpg",
      url: '#'
    },
    {
      id: 13,
      title: "IDENTIDADE CORPORATIVA",
      //subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/IDENTIDADE CORPORATIVA.jpg",
      largeImageUrl: ["/images/IDENTIDADE CORPORATIVA.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 14,
      title: "LIVROS",
      //subtitle: "Mug with awesome style",
      imageUrl: "/images/LIVROS.png",
      largeImageUrl: ["/images/LIVROS.png"]
    },
    {
      id: 15,
      title: "Logo Aplicações",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/Logo Aplicações.jpg",
      largeImageUrl: ["/images/Logo Aplicações.jpg"]
    },
    {
      id: 16,
      title: "PAVILAR vertical",
      //subtitle: "Pendrive with great design & flexible.",
      imageUrl: "/images/PAVILAR vertical.jpg",
      largeImageUrl: ["/images/PAVILAR vertical.jpg"],
      url: 'https://twitter.com'
    },
    {
      id: 17,
      title: "Logo - EXPRESSO DAS ILHAS",
      //subtitle: "Clip sticker mockup design.",
      imageUrl: "/images/EXPRESSO DAS ILHAS.png",
      largeImageUrl: ["/images/EXPRESSO DAS ILHAS.png"]
    },
    {
      id: 18,
      title: "Logo - COSMA",
      //subtitle: "Beautiful packet & product design.",
      imageUrl: "/images/COSMA.png",
      largeImageUrl: ["/images/COSMA.png"]
    },
    {
      id: 19,
      title: "DGT - IGT",
      //subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/DGT - IGT.png",
      largeImageUrl: ["/images/DGT - IGT.png"],
      url: '#'
    },
    {
      id: 20,
      title: "LOGO ORIGINAL - Casa Guga",
      //subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/LOGO ORIGINAL.png",
      largeImageUrl: [
        "/images/LOGO ORIGINAL.png",
        "/images/LOGO ORIGINAL.png"
      ],
      url: '#'
    },
    {
      id: 21,
      title: "TIA CONCHA - SHELL",
      //subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/TIA CONCHA - SHELL.jpg",
      url: '#'
    },
    {
      id: 22,
      title: "Policia Um Amigo",
      //subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/Policia Um Amigo.jpg",
      largeImageUrl: ["/images/Policia Um Amigo.jpg"],
      url: '#'
    },
    {
      id: 23,
      title: "TUBARAÃO MARTELO",
      //subtitle: "Mug with awesome style",
      imageUrl: "/images/TUBARAÃO MARTELO.jpg",
      largeImageUrl: ["/images/TUBARAÃO MARTELO.jpg"]
    }
  ],
  logos: [

    {
      id: 1,
      title: "ALO CV TOURS",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/logotipos/ALO CV TOURS.jpg",
      largeImageUrl: ["/images/logotipos/ALO CV TOURS.jpg"]
    },
    {
      id: 2,
      title: "BLUSHIT",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/logotipos/BLUSHIT.jpg",
      largeImageUrl: ["/images/logotipos/BLUSHIT.jpg"]
    },
    {
      id: 3,
      title: "CATERING SERVICE",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/logotipos/CATERING SERVICE.jpg",
      largeImageUrl: ["/images/logotipos/CATERING SERVICE.jpg"]
    },
    {
      id: 4,
      title: "COSMA",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/logotipos/COSMA.jpg",
      largeImageUrl: ["/images/logotipos/COSMA.jpg"]
    },
    {
      id: 5,
      title: "DGT - IGT",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/logotipos/DGT - IGT.jpg",
      largeImageUrl: ["/images/logotipos/DGT - IGT.jpg"]
    },
    {
      id: 6,
      title: "ESPAÇO LIZY",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/logotipos/espaco-lizy.jpg",
      largeImageUrl: ["/images/logotipos/espaco-lizy.jpg"]
    },
    {
      id: 7,
      title: "EXPRESS+",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/logotipos/EXPRESS+.jpg",
      largeImageUrl: ["/images/logotipos/EXPRESS+.jpg"]
    },
    {
      id: 8,
      title: "EXPRESSO DAS ILHAS",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/logotipos/EXPRESSO DAS ILHAS.jpg",
      largeImageUrl: ["/images/logotipos/EXPRESSO DAS ILHAS.jpg"]
    },
    {
      id: 9,
      title: "MORABEZA RADIO",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/logotipos/MORABEZA RADIO.jpg",
      largeImageUrl: ["/images/logotipos/MORABEZA RADIO.jpg"]
    },
    {
      id: 10,
      title: "MUNDITRADING",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/logotipos/MUNDITRADING.jpg",
      largeImageUrl: ["/images/logotipos/MUNDITRADING.jpg"]
    },

    {
      id: 11,
      title: "PAVILAR",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/logotipos/PAVILAR.jpg",
      largeImageUrl: ["/images/logotipos/PAVILAR.jpg"]
    },

    {
      id: 12,
      title: "POUSADAS DOS LEITÕES",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/logotipos/pousadas-dos-leitoes.jpg",
      largeImageUrl: ["/images/logotipos/pousadas-dos-leitoes.jpg"]
    },
    {
      id: 13,
      title: "PROEMPRESA",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/logotipos/PROEMPRESA.jpg",
      largeImageUrl: ["/images/logotipos/PROEMPRESA.jpg"]
    },
    {
      id: 14,
      title: "PROTEGE",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/logotipos/PROTEGE.jpg",
      largeImageUrl: ["/images/logotipos/PROTEGE.jpg"]
    },
    {
      id: 15,
      title: "SIBITCHI",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/logotipos/SIBITCHI.jpg",
      largeImageUrl: ["/images/logotipos/SIBITCHI.jpg"]
    },
    {
      id: 16,
      title: "SIMPÓSIO",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/logotipos/simposito.jpg",
      largeImageUrl: ["/images/logotipos/simposito.jpg"]
    },
    {
      id: 17,
      title: "STARTUP",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/logotipos/STARTUP.jpg",
      largeImageUrl: ["/images/logotipos/STARTUP.jpg"]
    },
    {
      id: 18,
      title: "ZAU",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/logotipos/ZAU.jpg",
      largeImageUrl: ["/images/logotipos/ZAU.jpg"]
    },
    
   
  ],
  identidade: [

    {
      id: 1,
      title: "DGT",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/identidade-corporativa/DGT.jpg",
      largeImageUrl: ["/images/identidade-corporativa/DGT.jpg"]
    },
    {
      id: 2,
      title: "MARÇO LILAS",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/identidade-corporativa/marco-lilas.jpg",
      largeImageUrl: ["/images/identidade-corporativa/marco-lilas.jpg"]
    },
    {
      id: 3,
      title: "PAVILAR",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/identidade-corporativa/PAVILAR.jpg",
      largeImageUrl: ["/images/identidade-corporativa/PAVILAR.jpg"]
    },
    {
      id: 4,
      title: "GUARDA MUNICIPAL - BONES",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/identidade-corporativa/GUARDA-MUNICIPAL/bones.jpg",
      largeImageUrl: ["/images/identidade-corporativa/GUARDA-MUNICIPAL/bones.jpg"]
    },
    {
      id: 5,
      title: "GUARDA MUNICIPAL - CALÇÃO PARA PATRULHA",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/identidade-corporativa/GUARDA-MUNICIPAL/calcao-para-patrulha.jpg",
      largeImageUrl: ["/images/identidade-corporativa/GUARDA-MUNICIPAL/calcao-para-patrulha.jpg"]
    },
    {
      id: 6,
      title: "GUARDA MUNICIPAL - CALÇA PARA OPERACIONAIS",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/identidade-corporativa/GUARDA-MUNICIPAL/calca-para-operacionais.jpg",
      largeImageUrl: ["/images/identidade-corporativa/GUARDA-MUNICIPAL/calca-para-operacionais.jpg"]
    },
    {
      id: 7,
      title: "GUARDA MUNICIPAL - DISTINTIVO SIMULAÇÃO",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/identidade-corporativa/GUARDA-MUNICIPAL/distintivo-simulacao.jpg",
      largeImageUrl: ["/images/identidade-corporativa/GUARDA-MUNICIPAL/distintivo-simulacao.jpg"]
    },
    {
      id: 8,
      title: "GUARDA MUNICIPAL - DIVISA",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/identidade-corporativa/GUARDA-MUNICIPAL/Divisa.jpg",
      largeImageUrl: ["/images/identidade-corporativa/GUARDA-MUNICIPAL/Divisa.jpg"]
    },
    {
      id: 9,
      title: "GUARDA MUNICIPAL - DIVISA",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/identidade-corporativa/GUARDA-MUNICIPAL/divisa2.jpg",
      largeImageUrl: ["/images/identidade-corporativa/GUARDA-MUNICIPAL/divisa2.jpg"]
    },
    {
      id: 10,
      title: "GUARDA MUNICIPAL - DIVISA",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/identidade-corporativa/GUARDA-MUNICIPAL/divisa3.jpg",
      largeImageUrl: ["/images/identidade-corporativa/GUARDA-MUNICIPAL/divisa3.jpg"]
    },
    {
      id: 11,
      title: "GUARDA MUNICIPAL - DIVISA",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/identidade-corporativa/GUARDA-MUNICIPAL/divisa9.jpg",
      largeImageUrl: ["/images/identidade-corporativa/GUARDA-MUNICIPAL/divisa9.jpg"]
    },
    {
      id: 12,
      title: "GUARDA MUNICIPAL - IMAS",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/identidade-corporativa/GUARDA-MUNICIPAL/imas.jpg",
      largeImageUrl: ["/images/identidade-corporativa/GUARDA-MUNICIPAL/imas.jpg"]
    },
    {
      id: 13,
      title: "GUARDA MUNICIPAL - JAQUETA",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/identidade-corporativa/GUARDA-MUNICIPAL/Jaqueta.jpg",
      largeImageUrl: ["/images/identidade-corporativa/GUARDA-MUNICIPAL/Jaqueta.jpg"]
    },
    {
      id: 14,
      title: "GUARDA MUNICIPAL - PORTA CHAVES",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/identidade-corporativa/GUARDA-MUNICIPAL/Porta chaves2.jpg",
      largeImageUrl: ["/images/identidade-corporativa/GUARDA-MUNICIPAL/Porta chaves2.jpg"]
    },
    {
      id: 15,
      title: "GUARDA MUNICIPAL - T-shirt POLO",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/identidade-corporativa/GUARDA-MUNICIPAL/T-shirt POLO.jpg",
      largeImageUrl: ["/images/identidade-corporativa/GUARDA-MUNICIPAL/T-shirt POLO.jpg"]
    },
    {
      id: 16,
      title: "GUARDA MUNICIPAL - VIATURA",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/identidade-corporativa/GUARDA-MUNICIPAL/viatura.jpg",
      largeImageUrl: ["/images/identidade-corporativa/GUARDA-MUNICIPAL/viatura.jpg"]
    },
    

   
    
   
  ],
  mascotes: [

    {
      id: 1,
      title: "Polícia é um Amigo",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/mascotes/policia-um-amigo.jpg",
      largeImageUrl: ["/images/mascotes/policia-um-amigo.jpg"]
    },
    {
      id: 2,
      title: "TIA CONCHA - SHELL",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/mascotes/TIA CONCHA - SHELL.jpg",
      largeImageUrl: ["/images/mascotes/TIA CONCHA - SHELL.jpg"]
    },
    {
      id: 3,
      title: "TUBARÃO MARTELO",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/mascotes/tubarao-martelo.jpg",
      largeImageUrl: ["/images/mascotes/tubarao-martelo.jpg"]
    },
   
  ],
  revistas: [

    {
      id: 1,
      title: "CABO VERDE TRADEINVEST",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/revistas/CABO VERDE TRADEINVEST.jpg",
      largeImageUrl: ["/images/revistas/CABO VERDE TRADEINVEST.jpg"]
    },
    {
      id: 2,
      title: "EMPROFAC",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/revistas/EMPROFAC.jpg",
      largeImageUrl: ["/images/revistas/EMPROFAC.jpg"]
    },
    {
      id: 3,
      title: "MCA CABO VERDE II",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/revistas/MCA CABO VERDE II.jpg",
      largeImageUrl: ["/images/revistas/MCA CABO VERDE II.jpg"]
    },
  ],
  livros: [

    {
      id: 1,
      title: "GERALDINA ALMEIDA",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/livros/GERALDINA ALMEIDA.jpg",
      largeImageUrl: ["/images/livros/GERALDINA ALMEIDA.jpg"]
    },
    {
      id: 2,
      title: "HUMBERTO CARDOSO",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/livros/HUMBERTO CARDOSO.jpg",
      largeImageUrl: ["/images/livros/HUMBERTO CARDOSO.jpg"]
    },
    {
      id: 3,
      title: "MARIO PAIS",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/livros/MARIO PAIS.jpg",
      largeImageUrl: ["/images/livros/MARIO PAIS.jpg"]
    },
  ],
  agendas: [

    {
      id: 1,
      title: "ANAS",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/agenda-calendarios/ANAS.jpg",
      largeImageUrl: ["/images/agenda-calendarios/ANAS.jpg"]
    },
    {
      id: 2,
      title: "CABO-VERDE TARDEINVEST",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/agenda-calendarios/CABO-VERDE TARDEINVEST.jpg",
      largeImageUrl: ["/images/agenda-calendarios/CABO-VERDE TARDEINVEST.jpg"]
    },
    {
      id: 3,
      title: "EMPROFAC",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/agenda-calendarios/EMPROFAC.jpg",
      largeImageUrl: ["/images/agenda-calendarios/EMPROFAC.jpg"]
    },
  ],
  videos: [
    {
      id: 1,
      title: "Start UP",
      //subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "https://i.ytimg.com/vi/QBACgGG1I_8/hqdefault.jpg?sqp=-oaymwE2CNACELwBSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB_gmAAtAFigIMCAAQARhlIE8oSTAP&rs=AOn4CLAAniQ9ix0UIa4vPw_ygbqmP_0SJg",
      // largeImageUrl: ["https://i.ytimg.com/vi/QBACgGG1I_8/hqdefault.jpg?sqp=-oaymwE1CKgBEF5IVfKriqkDKAgBFQAAiEIYAXABwAEG8AEB-AH-CYAC0AWKAgwIABABGGUgTyhJMA8=&rs=AOn4CLCgyHihNEu4TGNcqq5c0wyli5qrZw"],
      url: 'https://youtu.be/QBACgGG1I_8',
      youtubeVideoId: 'QBACgGG1I_8?si=2mppJf3wkXJu2akz'
    },
    {
      id: 2,
      title: "Maratona de inovação online",
      //subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "https://i.ytimg.com/vi/8GD_RO4XLFE/hqdefault.jpg?sqp=-oaymwE1CKgBEF5IVfKriqkDKAgBFQAAiEIYAXABwAEG8AEB-AH-CYAC0AWKAgwIABABGCUgQSh_MA8=&rs=AOn4CLDrcSxLHsE82gE3qVwBpWGAVFiLcg",
      // largeImageUrl: ["https://i.ytimg.com/vi/QBACgGG1I_8/hqdefault.jpg?sqp=-oaymwE1CKgBEF5IVfKriqkDKAgBFQAAiEIYAXABwAEG8AEB-AH-CYAC0AWKAgwIABABGGUgTyhJMA8=&rs=AOn4CLCgyHihNEu4TGNcqq5c0wyli5qrZw"],
      url: 'https://youtu.be/8GD_RO4XLFE',
      youtubeVideoId: '8GD_RO4XLFE?si=QKMDJ7lop_bNCrNT'
    },
    {
      id: 3,
      title: "Startap Alt Final 4",
      //subtitle: "Beautiful mug with logo.",
      imageUrl: "https://i.ytimg.com/vi/JT8WStSkTKw/hqdefault.jpg?sqp=-oaymwE1CKgBEF5IVfKriqkDKAgBFQAAiEIYAXABwAEG8AEB-AH-CYAC0AWKAgwIABABGGUgZShlMA8=&rs=AOn4CLDu4wzqqqrstOFXKqD_EMbAdzFYoA",
      // largeImageUrl: ["https://i.ytimg.com/vi/QBACgGG1I_8/hqdefault.jpg?sqp=-oaymwE1CKgBEF5IVfKriqkDKAgBFQAAiEIYAXABwAEG8AEB-AH-CYAC0AWKAgwIABABGGUgTyhJMA8=&rs=AOn4CLCgyHihNEu4TGNcqq5c0wyli5qrZw"],
      url: 'https://youtu.be/JT8WStSkTKw',
      youtubeVideoId: 'JT8WStSkTKw?si=YrFRSbSIGVHcdktV'
    },
    {
      id: 4,
      title: "Proempresa",
      //subtitle: "A pen holder with beautiful design.",
      imageUrl: "https://i.ytimg.com/vi/wCoVPilbo5s/hqdefault.jpg?sqp=-oaymwE1CKgBEF5IVfKriqkDKAgBFQAAiEIYAXABwAEG8AEB-AH-CYAC0AWKAgwIABABGGUgZShlMA8=&rs=AOn4CLAKQaFZjgLfC0UulfMMRddnBnwUZw",
      url: 'https://youtu.be/wCoVPilbo5s',
      youtubeVideoId: 'wCoVPilbo5s?si=vCzcogUNWiA5zKg-'
    },
    {
      id: 5,
      title: "Filme 2 Final TV",
      //subtitle: "Mug with awesome style",
      imageUrl: "https://i.ytimg.com/vi/96pZN6W0DLg/hqdefault.jpg?sqp=-oaymwE1CKgBEF5IVfKriqkDKAgBFQAAiEIYAXABwAEG8AEB-AH-CYAC0AWKAgwIABABGGUgUyhKMA8=&rs=AOn4CLC-OojoqKr_pNePgwyHHog4cDsN6w",
      url: 'https://youtu.be/96pZN6W0DLg',
      youtubeVideoId: '96pZN6W0DLg?si=ftPYnuE4qysLapFv'
    },
    {
      id: 6,
      title: "Março Lilás ",
      //subtitle: "Free pendrive mockup design.",
      imageUrl: "https://i.ytimg.com/vi/ODTfHfjjTNY/hqdefault.jpg?sqp=-oaymwE1CKgBEF5IVfKriqkDKAgBFQAAiEIYAXABwAEG8AEB-AH-CYAC0AWKAgwIABABGGUgTig9MA8=&rs=AOn4CLDeuGKTUPtielEeFh5yVK5u7Nsx9g",
      url: 'https://youtu.be/ODTfHfjjTNY',
      youtubeVideoId: 'ODTfHfjjTNY?si=zXLAUt6ge9CIYmnX'
    },
    {
      id: 7,
      title: "Caminhada dia de diabetes Emprofac ",
      //subtitle: "Pendrive with great design & flexible.",
      imageUrl: "https://i.ytimg.com/vi/e6_8WIYPyTA/hqdefault.jpg?sqp=-oaymwE1CKgBEF5IVfKriqkDKAgBFQAAiEIYAXABwAEG8AEB-AH-CYAC0AWKAgwIABABGE4gVyhlMA8=&rs=AOn4CLCs4NRNDdp4tksNfu32nFUiAu-Q2w",
      url: 'https://youtu.be/e6_8WIYPyTA',
      youtubeVideoId: 'e6_8WIYPyTA?si=MGfk9KR9tCRpXFs4'
    }

  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2018 - Present",
        position: "Frontend Web Developer",
        company: "Abc Company",
        details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      },
      {
        id: 2,
        year: "2016 - 2018",
        position: "Frontend Web Developer",
        company: "CBA Company",
        details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      },
      {
        id: 3,
        year: "2014 - 1016",
        position: "UI/UX Designer",
        company: "Example Company",
        details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2018 - 2019",
        graduation: "Master of Science",
        university: "Abc University",
        details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      },
      {
        id: 2,
        year: "2016 - 2018",
        graduation: "Bachelor of Science",
        university: "Abc University",
        details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      },
      {
        id: 3,
        year: "2015 - 2016",
        graduation: "Higher Schoold Graduation",
        university: "Abc College",
        details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      }
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'Markdown & Html supported blog.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/markdown-html-supported-blog.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 2,
      title: 'Installing NodeJS on your device.',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/installing-nodejs-on-your-device.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 3,
      title: 'UI/UX design starter with Adobe XD.',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/uiux-design-starter-with-adobe-xd.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 4,
      title: 'Boost your post for increasing sales.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/boost-your-post-for-increasing-sales.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 5,
      title: 'Difference between GatsbyJS & NextJS.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/difference-between-gatsbyjs-and-nextjs.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 6,
      title: 'How to choose javascript framework for project.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/how-to-choose-javascript-framework-for-project.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 7,
      title: 'Web automation with python language.',
      featuredImage: '/images/blog-image-7.jpg',
      filesource: '../../blog/web-automation-with-python-language.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 8,
      title: 'Time to use latest technology for creating a website.',
      featuredImage: '/images/blog-image-8.jpg',
      filesource: '../../blog/time-to-use-latest-technology-for-creating-a-website.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 9,
      title: 'Think out of the box.',
      featuredImage: '/images/blog-image-9.jpg',
      filesource: '../../blog/think-out-of-the-box.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 10,
      title: 'Trending designs in 2020.',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/trending-designs-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 11,
      title: 'How to get 10k instagram followers?',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/how-to-get-10k-instagram-followers.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 12,
      title: 'What NodeJS can do?',
      featuredImage: '/images/blog-image-3.jpg',
      filesource: '../../blog/what-nodejs-can-do.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 13,
      title: 'Futures of javascript.',
      featuredImage: '/images/blog-image-4.jpg',
      filesource: '../../blog/future-of-javascript.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 14,
      title: 'Popular javascript library in 2020.',
      featuredImage: '/images/blog-image-5.jpg',
      filesource: '../../blog/popular-javascript-library-in-2020.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    },
    {
      id: 15,
      title: 'Promrammers must read books.',
      featuredImage: '/images/blog-image-6.jpg',
      filesource: '../../blog/programmers-must-read-books.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020"
    }
  ],
  contactInfo: {
    phoneNumbers: ['+238 970 50 30'],
    emailAddress: ['hedersoares.cv@outlook.com', 'info@hedersoares.cv'],
    address: "Palmarejo, Praia, Cabo Verde"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/videos").reply(config => {
  const response = database.videos;
  return [200, response];
});

Mock.onGet("/api/logos").reply(config => {
  const response = database.logos;
  return [200, response];
});

Mock.onGet("/api/identidade").reply(config => {
  const response = database.identidade;
  return [200, response];
});

Mock.onGet("/api/mascotes").reply(config => {
  const response = database.mascotes;
  return [200, response];
});

Mock.onGet("/api/revistas").reply(config => {
  const response = database.revistas;
  return [200, response];
});

Mock.onGet("/api/livros").reply(config => {
  const response = database.livros;
  return [200, response];
});

Mock.onGet("/api/agendas").reply(config => {
  const response = database.agendas;
  return [200, response];
});



Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});