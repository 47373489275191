import React, { useState } from "react";
import * as Icon from "react-feather";
import ProgressiveImage from 'react-progressive-image';

function VideoPortfolio(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const { title, subtitle, imageUrl, url, youtubeVideoId } = props.content;

  const handleOpenModal = () => {
    // Fecha o modal atual antes de abrir um novo
    setIsOpen(false);
    setCurrentVideoId(null);

    // Abre o modal com o novo vídeo após garantir o fechamento
    setTimeout(() => {
      setCurrentVideoId(youtubeVideoId);
      setIsOpen(true);
    }, 100);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setCurrentVideoId(null);  // Limpa o vídeo atual
  };

  const handleBackdropClick = (e) => {
    if (e.target.classList.contains('modal')) {
      handleCloseModal();
    }
  };

  return (
    <div className="mi-portfolio mi-portfolio-visible">
      <div className="mi-portfolio-image">
        <ProgressiveImage
          src={imageUrl}
          placeholder="/images/portfolio-image-placeholder.png"
        >
          {src => <img src={src} alt={title} />}
        </ProgressiveImage>
        <ul>
          {youtubeVideoId && (
            <li>
              <button onClick={handleOpenModal}>
                <Icon.PlayCircle />
              </button>
            </li>
          )}
          {url && (
            <li>
              <a rel="noopener noreferrer" target="_blank" href={url}>
                <Icon.Link />
              </a>
            </li>
          )}
        </ul>
      </div>
      <h5>
        {url ? (
          <a rel="noopener noreferrer" target="_blank" href={url}>
            {title}
          </a>
        ) : (
          title
        )}
      </h5>
      {subtitle && <h6>{subtitle}</h6>}
      {currentVideoId && (
        <div
          className={`modal fade ${isOpen ? 'show' : ''}`}
          id="videoModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="videoModalLabel"
          aria-hidden={!isOpen}
          style={{ display: isOpen ? 'block' : 'none' }}
          onClick={handleBackdropClick}
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="videoModalLabel">
                  Vídeo do YouTube
                </h5>
                <button
                  type="button"
                  className="close"
                  aria-label="Fechar"
                  onClick={handleCloseModal}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <iframe
                  width="860"
                  height="515"
                  src={`https://www.youtube.com/embed/${currentVideoId}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoPortfolio;
