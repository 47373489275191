import axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as Icon from "react-feather";
import Layout from "../components/Layout";
import Pagination from "../components/Pagination";
import PortfoliosView from "../components/PortfoliosView";
import Sectiontitle from "../components/Sectiontitle";
import Spinner from "../components/Spinner";
import { NavLink } from "react-router-dom";


function Portfolios() {
  const [portfolios, setPortfoios] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [portfoliosPerPage] = useState(9);

  useEffect(() => {
    let mounted = true;
    axios.get("/api/portfolios").then((response) => {
      if (mounted) {
        setPortfoios(response.data);
      }
    });
    return () => (mounted = false);
  }, []);

  const indexOfLastPortfolios = currentPage * portfoliosPerPage;
  const indexOfFirstPortfolios = indexOfLastPortfolios - portfoliosPerPage;
  const currentPortfolios = portfolios.slice(
    indexOfFirstPortfolios,
    indexOfLastPortfolios
  );

  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  return (
    <Layout>
      <Helmet>
        <title>Portfolios - Heder Soares</title>
        <meta
          name="description"
          content="Heder Soares Designer Portfolios Page"
        />
      </Helmet>
      <Suspense fallback={<Spinner />}>
        <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
          <div className="container">
            <Sectiontitle title="Portfolios" />
            {/* {<PortfoliosView portfolios={currentPortfolios} />}
            {!(portfolios.length > portfoliosPerPage) ? null : (
              <Pagination
                className="mt-50"
                itemsPerPage={portfoliosPerPage}
                totalItems={portfolios.length}
                paginate={paginate}
                currentPage={currentPage}
              />
            )} */}
            <div>
              <ul className="mi-portfolio-grid">
                <li className="card">
                  <NavLink to="/videos" className="d-flex flex-column justify-itens-center align-items-center">
                    <Icon.Folder size={200} color="#a4acc4" />
                    <h5>Videos</h5>
                  </NavLink>
                </li>
                <li className="card">
                  <NavLink to="/logos" className="d-flex flex-column justify-itens-center align-items-center">
                    <Icon.Folder size={200} color="#a4acc4" />
                    <h5>Logotipos</h5>
                  </NavLink>
                </li>
                <li className="card">
                  <NavLink to="/identidade" className="d-flex flex-column justify-itens-center align-items-center">
                    <Icon.Folder size={200} color="#a4acc4" />
                    <h5>Identidade Corporativa</h5>
                  </NavLink>
                </li>
                <li className="card">
                  <NavLink to="/mascotes" className="d-flex flex-column justify-itens-center align-items-center">
                    <Icon.Folder size={200} color="#a4acc4" />
                    <h5>Mascotes</h5>
                  </NavLink>
                </li>
                <li className="card">
                  <NavLink to="/revistas" className="d-flex flex-column justify-itens-center align-items-center">
                    <Icon.Folder size={200} color="#a4acc4" />
                    <h5>Revistas</h5>
                  </NavLink>
                </li>
                <li className="card">
                  <NavLink to="/livros" className="d-flex flex-column justify-itens-center align-items-center">
                    <Icon.Folder size={200} color="#a4acc4" />
                    <h5>Livros</h5>
                  </NavLink>
                </li>
                <li className="card">
                  <NavLink to="/agendas" className="d-flex flex-column justify-itens-center align-items-center">
                    <Icon.Folder size={200} color="#a4acc4" />
                    <h5>Agendas e Calendários</h5>
                  </NavLink>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </Suspense>
    </Layout>
  );
}

export default Portfolios;
