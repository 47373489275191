import axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as Icon from "react-feather";
import Layout from "../components/Layout";
import Pagination from "../components/Pagination";
import PortfoliosView from "../components/PortfoliosView";
import Sectiontitle from "../components/Sectiontitle";
import Spinner from "../components/Spinner";

function Mascotes() {
    const [portfolios, setPortfoios] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [portfoliosPerPage] = useState(9);

    useEffect(() => {
        let mounted = true;
        axios.get("/api/mascotes").then((response) => {
            if (mounted) {
                setPortfoios(response.data);
            }
        });
        return () => (mounted = false);
    }, []);

    const indexOfLastPortfolios = currentPage * portfoliosPerPage;
    const indexOfFirstPortfolios = indexOfLastPortfolios - portfoliosPerPage;
    const currentPortfolios = portfolios.slice(
        indexOfFirstPortfolios,
        indexOfLastPortfolios
    );

    const paginate = (e, pageNumber) => {
        e.preventDefault();
        setCurrentPage(pageNumber);
    };

    return (
        <Layout>
            <Helmet>
                <title>Mascotes - Heder Soares</title>
                <meta
                    name="description"
                    content="Heder Soares Designer Mascotes Page"
                />
            </Helmet>
            <Suspense fallback={<Spinner />}>
                <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
                    <div className="container">
                        <a href="/portfolios " >
                            <Icon.ArrowLeft />
                            Portfolio</a>
                        <Sectiontitle title="Mascotes" />

                        {<PortfoliosView portfolios={currentPortfolios} />}
                        {!(portfolios.length > portfoliosPerPage) ? null : (
                            <Pagination
                                className="mt-50"
                                itemsPerPage={portfoliosPerPage}
                                totalItems={portfolios.length}
                                paginate={paginate}
                                currentPage={currentPage}
                            />
                        )}
                    </div>
                </div>
            </Suspense>
        </Layout>
    );
}

export default Mascotes;
