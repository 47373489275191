import React from "react";
import VideoPortfolio from "./VideoPortfolio";

function PortfoliosVideoView({ portfolios }) {
  return (
    <div className="row mt-30-reverse">
      {portfolios.map(portfolio => (
        <div className="col-lg-4 col-md-6 col-12 mt-30" key={portfolio.id} >
          <VideoPortfolio content={portfolio} />
        </div>
      ))}
    </div>
  );
}

export default PortfoliosVideoView;
